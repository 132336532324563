<template>
  <div class="hello">
    正在开通，请稍后...
  </div>
</template>

<script>
import axios from 'axios';

import wx from "weixin-js-sdk";

export default {
  name: "HelloWorld",
  data() {
    return {
      localUrl: "https://weixinapi.bosny.cn:10043",
      //小程序openId
      appletOpenId: "",
      //小程序登录token
      token: "",
      sessionid: "",
      uid:"",
      //
      code:"",

    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    this.code = code;

    const uid = params.get("uid");
    this.uid = uid;

    //调用接口进行绑定
    this.bindOpenId();
  },
  methods: {
    bindOpenId() {
      const url = this.localUrl + "/getOpenidPlus?code="+this.code+"&uid="+this.uid;
      axios.get(url)
        .then((response) => {
          if (response.status == 200 && response.data.code == 20000) {
            alert("开启成功");
            //跳回小程序
            //wx.miniProgram.navigateBack({ delta: 1 });
            wx.miniProgram.navigateTo({url:"/pages/set/update?isOpen=1"});
          }else{
            alert("开启失败："+response.data.msg);
            //跳回小程序
            //wx.miniProgram.navigateBack({ delta: 1 });
            wx.miniProgram.navigateTo({url:"/pages/set/update?isOpen=0"});
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
